import * as React from "react";
import { graphql, Link } from "gatsby";
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CDefinition,
  CBtnList,
} from "../../components/_index";
import { size } from "lodash";
import { setLang } from "../../utils/set-lang";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <section className="l_sect02">
        <h1 className="c_headingLv2">特定商取引に関する法律に基づく表示</h1>
        <LWrap size="small">
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">
              特定商取引に関する法律に基づく表示（宿泊予約）
            </h2>
            <CDefinition
              data={[
                {
                  title: "販売事業者",
                  text: <>三菱地所ホテルズ＆リゾーツ株式会社</>,
                },
                {
                  title: "販売責任者",
                  text: <>取締役社長（代） 大島 正也</>,
                },
                {
                  title: "所在地／連絡先",
                  text: (
                    <>
                      〒107－0062
                      <br />
                      東京都港区南青山1-1-1 新青山ビル東館13F
                      <br />
                      運営推進部
                      <br />
                      TEL <a href="tel:0367731864">03-6773-1864</a>
                    </>
                  ),
                },
                {
                  title: "宿泊代金",
                  text: (
                    <>
                      宿泊プランによります。詳細は宿泊プラン詳細ページをご参照ください。
                    </>
                  ),
                },
                {
                  title: "宿泊代金以外の必要料金",
                  text: (
                    <>
                      各都道府県における宿泊税をご負担いただきます。詳細は
                      <Link
                        to="https://www.royalparkhotels.co.jp/rphs/l52c6rgpn/"
                      >
                        宿泊税のご案内ページ
                      </Link>
                      をご参照ください。
                    </>
                  ),
                },
                {
                  title: "申込方法",
                  text: <>公式ウェブサイトよりお申し込みください。</>,
                },
                {
                  title: "お問合せ",
                  text: (
                    <>宿泊に関するお問合せは、各ホテルへお願いいたします。</>
                  ),
                },
                {
                  title: "⽀払時期／⽀払⽅法",
                  text: (
                    <>
                      宿泊プランによります。詳細は宿泊プラン詳細ページをご参照ください。
                      <br />
                      クレジットカードにて事前決済の場合︓オンライン予約時にクレジットカードにてお⽀払い
                      <br />
                      現地決済の場合︓チェックイン時にお支払い
                    </>
                  ),
                },
                {
                  title: "宿泊予約の成⽴",
                  text: <>お申込みが完了した時点で予約の成⽴となります。</>,
                },
                {
                  title: "返金",
                  text: <>宿泊後の返金は致しておりません。</>,
                },
                {
                  title: "キャンセル",
                  text: (
                    <>
                      宿泊約款に準じたキャンセル料を申し受けます。
                      <br />
                      宿泊プランにより別途キャンセル規定を設けている場合は、それに準じます。
                    </>
                  ),
                },
              ]}
            />
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">
              特定商取引に関する法律に基づく表示（イベント予約）
            </h2>
            <CDefinition
              data={[
                {
                  title: "販売事業者",
                  text: <>三菱地所ホテルズ＆リゾーツ株式会社</>,
                },
                {
                  title: "販売責任者",
                  text: <>取締役社長（代） 大島 正也</>,
                },
                {
                  title: "所在地／連絡先",
                  text: (
                    <>
                      〒107－0062
                      <br />
                      東京都港区南青山1-1-1 新青山ビル東館13F
                      <br />
                      運営推進部
                      <br />
                      TEL <a href="tel:0367731864">03-6773-1864</a>
                    </>
                  ),
                },
                {
                  title: "イベント代金",
                  text: (
                    <>
                      イベントによります。詳細はイベント詳細ページをご参照ください。
                    </>
                  ),
                },
                {
                  title: "イベント代⾦以外の必要料金",
                  text: (
                    <>
                      チケット配送の場合、送料が必要になる場合がございます。詳細はイベント詳細ページをご参照ください。
                    </>
                  ),
                },
                {
                  title: "申込方法",
                  text: <>公式ウェブサイトよりお申し込みください。</>,
                },
                {
                  title: "お問合せ",
                  text: (
                    <>
                      イベントに関するお問合せは、各ホテルへお願いいたします。
                    </>
                  ),
                },
                {
                  title: "⽀払時期／⽀払⽅法",
                  text: (
                    <>
                      イベントによります。詳細はイベント詳細ページをご参照ください。
                      <br />
                      クレジットカードにて事前決済の場合︓オンライン予約時にクレジットカードにてお⽀払い
                      <br />
                      銀行振込・現地決済の場合︓お支払い情報のご案内に指定された期日までにお支払い
                      <br />
                      上記と異なる条件の場合は、イベント詳細ページにて条件を記載いたします。
                    </>
                  ),
                },
                {
                  title: "イベント予約の成立",
                  text: <>お申込みが完了した時点で予約の成⽴となります。</>,
                },
                {
                  title: "返金",
                  text: <>イベント開催後の返金は致しておりません。</>,
                },
                {
                  title: "キャンセル",
                  text: (
                    <>
                      イベントによります。詳細はイベント詳細ページをご参照ください。
                    </>
                  ),
                },
              ]}
            />
          </section>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
